import { db } from '@/services/firebase/instance'
import { doc, setDoc, getDoc, updateDoc, deleteDoc, onSnapshot, Unsubscribe, writeBatch, FieldPath, deleteField, collection,query, where } from 'firebase/firestore';
import { Prewedding, Images } from '@/interfaces/database/prewedding';

const preweddingDatabase = () => {
  const collectionName = 'prewedding'

  const _create = async (eventId: string, payload: Prewedding) => {
    const docRef = doc(db, collectionName, eventId)
    return await setDoc(docRef, payload)
  }

  const _get = async (uid: string): Promise<Prewedding | null> => {
    const docRef = doc(db, collectionName, uid)
    const docSnap = await getDoc(docRef)
    const preweddingData: Prewedding = docSnap.data() as Prewedding
    if(preweddingData) return preweddingData
    return null
  }

  const _update = async (eventId: string, payload: Prewedding) => {
    const docRef = doc(db, "events", eventId, "data", "photos")    
    const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {    
    return updateDoc(docRef, payload as any);
  } else {    
    await setDoc(docRef, payload as any);    
  }
    return updateDoc(docRef, payload as any)
  }
  
  const _remove = (eventId: string, qrIdOrUserId: string) => {
    const docId = `${eventId}:${qrIdOrUserId}`
    const docRef = doc(db, collectionName, docId)
    return deleteDoc(docRef)
  }



  function _realtimeFetch(eventId: string, callback: (event: Event) => void): Unsubscribe {
    const unsub = onSnapshot(doc(db, "events", eventId, "data", "photos"), (doc) => {
      callback(doc.data());
   
  });     
    return unsub;
  }

  const removeFieldPeriod = (eventId: string, keyName: string) => {
    const docRef = doc(db, "events", eventId, "data", "photos")
    return updateDoc(docRef, new FieldPath(keyName), deleteField())
  }

  return {
    _create,
    _get,
    _update,
    _remove,
 
    _realtimeFetch,
    removeFieldPeriod
  }
}

export default preweddingDatabase