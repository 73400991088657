import { endpointCloudfareApi } from "./enpoints";
import axios from "axios";

const cloudfareApi = () => {
  const removeImageById = async(imageId: string) => {
    let data = JSON.stringify({
      imageId: imageId,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: endpointCloudfareApi.cloud,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    const result =  await axios.request(config);
    console.log("remove cloudfare", result)
    return result
  };

  return { removeImageById };
};

export default cloudfareApi