import useWish from "@/composables/useWish";
import dayjs from "dayjs";
import preweddingStroage from "@/services/storage/prewedding";
import preweddingDatabase from "~/services/database/prewedding";
import { useEventStore } from "@/stores/event";
import _ from "lodash";
import { makeid } from "~/utils/makeid";
import cloudfareApi from "~/services/api/cloudfare"

export const usePreweddingStore = defineStore("prewedding", {
  state: () => ({
    imagesObj: null, // { timestamp: { hd: '', original, .... }, 123123: { hd: '', original, .... } }    
    totalImages:0
  }),
  getters: {
    limitUpload() {
			const eventStore = useEventStore()
			const packageName = eventStore.event.package
			if (packageName === 'free') { 
				return 30
			}

			if(packageName === 'pro') {
				return 200
			}

			return 300
		},
    imageList(): Array<{ key; filename; ["2k"]; hd; original; thumbnail }> {
      if (!this.imagesObj) {
        return [];
      }

      let imagesList = [];

      for (const key in this.imagesObj) {
        const image = this.imagesObj[key];    
        // let filenameWithoutExtension = image.filename.substring(0, image.filename.lastIndexOf("."));
        let filenameWithoutExtension = key
        const assign = { key: filenameWithoutExtension , ...image};
        imagesList.push(assign);
      }

      return _.orderBy(imagesList, ["createAt"], ["asc"]);
    },
  },
  actions: {
    async main() {
      this.realtimeFetch();
    },
    realtimeFetch() {
      const eventStore = useEventStore();
      const eventId = eventStore.eventId;

      preweddingDatabase()._realtimeFetch(eventId, (prewedding) => {
        this.imagesObj = prewedding;
      });
    },
    async uploadBatchImage(files: Array<Blob>, callback?: Function) {
      const eventStore = useEventStore();
      const eventId = eventStore.eventId;
      
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const timestamp = dayjs().valueOf().toString();
        const name = `prewedding-${makeid(6, null)}`
        const filename = `${name}.png`;
        const uploaded = await preweddingStroage().upload(eventId, file, filename);

        const image = transformImage(eventId, filename, uploaded.downloadURL);
        const uploadData = {...image, createAt:timestamp}
    
        if(!this.imagesObj){
          this.imagesObj = {
            [timestamp] : uploadData
          }
        }
        // await preweddingDatabase()._update(eventId, {
        //   [name]: uploadData,
        // });
        callback(i);
      }
      
      return;
    },

    async removeImage(keyName: string) {      
      const eventStore = useEventStore();
      const eventId = eventStore.eventId;

      const url = this.imagesObj[keyName].original;
      const isS3Image = _.includes(url, "amazonaws");

      if (isS3Image) {
        const filename = url.split("/prewedding/original/")[1];
        await Promise.all([
          preweddingDatabase().removeFieldPeriod(eventId, keyName),
          preweddingStroage().removeS3(eventId, filename),
          preweddingStroage().remove(eventId, filename),
        ]);
      } else {       
        
        // const metadata = await useStorage().getMetaDataFromHttpReference(
        //   this.imagesObj[keyName].original
        // );

        //const filename = this.imagesObj[keyName].result.filename;
        const imageId = this.imagesObj[keyName].result.id
        await Promise.all([
          preweddingDatabase().removeFieldPeriod(eventId, keyName),
          cloudfareApi().removeImageById(imageId)
          //preweddingStroage().remove(eventId, filename),
        ]);
      }
      return;
    },
  },
});

const transformImage = (eventId: string, filename: string, uploadUlr:string) => {
  // const image = {
  // 	filename,
  // 	'2k': `https://firebasestorage.googleapis.com/v0/b/wedding-wish/o/${eventId}%2Fprewedding%2F2k%2F${filename}?alt=media`,
  // 	hd: `https://firebasestorage.googleapis.com/v0/b/wedding-wish/o/${eventId}%2Fprewedding%2Fhd%2F${filename}?alt=media`,
  // 	original: `https://firebasestorage.googleapis.com/v0/b/wedding-wish/o/${eventId}%2Fprewedding%2Foriginal%2F${filename}?alt=media`,
  // 	thumbnail: `https://firebasestorage.googleapis.com/v0/b/wedding-wish/o/${eventId}%2Fprewedding%2Fthumbnail%2F${filename}?alt=media`,
  // 	webp: `https://firebasestorage.googleapis.com/v0/b/wedding-wish/o/${eventId}%2Fprewedding%2Fwebp%2F${filename}?alt=media`
  // }

  // const image = {
  //   filename,
  //   "2k": `https://photowish-images.s3.ap-southeast-1.amazonaws.com/${eventId}/prewedding/2k/${filename}`,
  //   hd: `https://photowish-images.s3.ap-southeast-1.amazonaws.com/${eventId}/prewedding/hd/${filename}`,
  //   original: `https://photowish-images.s3.ap-southeast-1.amazonaws.com/${eventId}/prewedding/original/${filename}`,
  //   thumbnail: `https://photowish-images.s3.ap-southeast-1.amazonaws.com/${eventId}/prewedding/thumbnail/${filename}`,
  //   webp: `https://photowish-images.s3.ap-southeast-1.amazonaws.com/${eventId}/prewedding/hd/${filename}`,
  // };

  const image = {
    filename,
    "2k": uploadUlr,
    hd: uploadUlr,
    original: uploadUlr,
    thumbnail: uploadUlr,
    webp:uploadUlr,
  };
  return image;
};
