import { UploadResult } from 'firebase/storage'
import useStorage from './storage'

const storagePrewedding = () => {
  const { upload: UPLOAD, remove: REMOVE, removeS3: REMOVES3} = useStorage()

  const upload = (eventId: string, file: Blob, filename: string): Promise<UploadResult> => {
    let destination = `/queue/${filename}`
    // let destination = `${eventId}/prewedding/original/${filename}`
    return UPLOAD(destination, file, {eventId,removebg:false, path:'photos', project:"photowishv2"})
  }

  const remove = async (eventId: string, filename: string): Promise<void> => {
    try {
      let resolutionType = ['2k', 'hd', 'thumbnail', 'original']
      // const imageType = ['webp','png', 'jpg', 'jpeg']
      const imageType = ['png']
      let promiseAll = []
      for (let i = 0; i < resolutionType.length; i++) {
        const type = resolutionType[i]
        const filenameOnly = filename.split('.')[0]
        imageType.forEach((imageType) => {
          let destination = `${eventId}/prewedding/${type}/${filenameOnly}.${imageType}`
          promiseAll.push(REMOVE(destination))
        })
      }

      await Promise.all(promiseAll)
    } catch(error) {
      console.log(error)
    }
    
    return 
  }

  const removeS3 = async (eventId: string, filename: string): Promise<void> => {
    let resolutionType = ['2k', 'hd', 'thumbnail', 'original']
    let promiseAll = []
    for (let i = 0; i < resolutionType.length; i++) {
      const type = resolutionType[i]
      filename = type == 'webp' ? `${filename.split('.')[0]}.webp` : filename

      let destination = `${eventId}/prewedding/${type}/${filename}`

      const respoonse = await REMOVES3(destination)
      promiseAll.push(respoonse)
    }
    
    return 
  }

  return {
    upload,
    removeS3,
    remove
  }
}

export default storagePrewedding